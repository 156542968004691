import { CallComponents } from './CallComponent';
import { getPresence, setPresence } from './GraphService';
import { AuthenticatedTemplate } from '@azure/msal-react';
import * as amc from '@amc-technology/davinci-api';
import React from 'react';
import UserComponent from './UserComponent';
import { TeamsCallAgent } from '@azure/communication-calling';
import { LOG_LEVEL } from '@amc-technology/davinci-api';

interface props {
  instance: any;
  csConfig: any;
  callAgent: any;
  logger: any;
}
export class Home extends React.Component<props> {
  connectionString: string;
  clientID: string;
  instance: any;
  teamsCallAgent?: TeamsCallAgent;
  channelToDaVinci: any;
  authProvider?: any;
  userId: string;
  callAgent: TeamsCallAgent;
  _lastHeight: number;
  davinciPresence = '';
  lastDavinciPresence = '';
  lastTeamsPresence = '';
  logger: any;
  presencePollingInterval: any;
  constructor(props: any) {
    super(props);
    this.logger = props.logger;
    this.callAgent = props.callAgent;
    this.instance = props.instance;
    this._lastHeight = 0;
    this.resize();
    this.connectionString = props.csConfig.connectionString;
    this.clientID = props.csConfig.clientID;
    const userObject = new UserComponent(this.instance, this.connectionString, this.clientID);
    this.authProvider = userObject.getAuthProvider();
    this.userId = userObject.getUserId();
    this.channelToDaVinci = props.csConfig?.channelToDaVinci;
    amc.registerOnPresenceChanged(this.onDaVinciPresenceChanged.bind(this));
    // Poll for teams presence every second. We decided we do not want to use webhooks :(
    this.logger.log(LOG_LEVEL.Critical, 'constructor', 'CREATING NEW POLLING INTERVAL');
    this.presencePollingInterval = setInterval(this.pollForTeamsPresence.bind(this), 5000);
    amc.registerOnLogout(() => {
      try {
        clearInterval(this.presencePollingInterval);
        this.instance.logoutPopup();
        //log
      } catch (exception) {
        //log
      }

      return Promise.resolve();
    });
  }

  async componentDidMount() {
    const functionName = 'componentDidMount';
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `Home Componenet : START`);

      this.resize();
      const presence = await getPresence(this.authProvider);
      const teamsPresence = presence.availability + '|' + presence.activity;
      const davinciPresence = this.channelToDaVinci[teamsPresence];
      if (davinciPresence && davinciPresence !== null && davinciPresence !== undefined && davinciPresence !== '') {
        this.lastTeamsPresence = presence.availability + '|' + presence.activity;
        amc.setPresence(davinciPresence);
      } else {
        this.logger.log(LOG_LEVEL.Debug, functionName, `Home Component : Presence not in studio configs ${teamsPresence}`);
      }

      this.logger.log(LOG_LEVEL.Trace, functionName, `Home Componenet : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `Home Componenet : ERROR`, error);
    }
  }

  /**
   * Setting the Presence in teams to match a presence changed event coming from click to act
   *
   * @param {any} data - presence/reason data from click to act
   * @memberof CallComponents
   */
  async setPresenceToTeamsCTA(data: any): Promise<void> {
    const functionName = `setPresenceToTeamsCTA`;
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `Home Componenet : START`);

      this.onDaVinciPresenceChanged(data.presence, data.reason, data.pending);

      this.logger.log(LOG_LEVEL.Trace, functionName, `Home Componenet : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `Home Componenet : ERROR`, error);
    }
  }

  /**
   * Change presence in Microsoft teams if there is a change in presence that is not initiated from this application
   *
   * @param {string} daVinciPresence - Presence in DaVinci
   * @param {string} reason - Reason for a presence change if there is a reason
   * @param {string} initiatingApp - App that initiated the change in presence
   * @memberof CallComponents
   */
  async onDaVinciPresenceChanged(daVinciPresence: string, reason?: string, initiatingApp?: string): Promise<void> {
    let functionName = 'onDaVinciPresenceChanged';
    this.logger.log(LOG_LEVEL.Trace, functionName, `Home Componenet : START`);
    if (initiatingApp !== this.props.csConfig.appName) {
      try {
        let teamsPresence;
        if (daVinciPresence !== this.lastDavinciPresence) {
          //Log
          if (daVinciPresence === null || daVinciPresence === '') {
            // Log error
            return Promise.reject();
          }
          let newDaVinciPresence = daVinciPresence;
          if (reason !== null) {
            newDaVinciPresence += '|' + reason;
          }

          if (reason) {
            teamsPresence = this.props.csConfig.DaVinciToChannel[`${daVinciPresence}|${reason}`];
          } else {
            teamsPresence = this.props.csConfig.DaVinciToChannel[daVinciPresence];
          }

          if (teamsPresence !== undefined && teamsPresence !== null && teamsPresence !== this.lastTeamsPresence && this.userId) {
            await this.setTeamsPresence(teamsPresence, this.userId, this.props.csConfig.clientID);
            amc.setPresence(daVinciPresence, reason);
            this.lastDavinciPresence = newDaVinciPresence;
            this.lastTeamsPresence = teamsPresence;
          }
        }
        this.logger.log(LOG_LEVEL.Trace, functionName, `Home Componenet : Presence changed to ${teamsPresence} : END`);
        return Promise.resolve();
      } catch (error) {
        this.logger.log(LOG_LEVEL.Error, functionName, `Home Componenet : ERROR`, error);
        return Promise.reject();
      }
    }
    this.logger.log(LOG_LEVEL.Trace, functionName, `Home Componenet: Rejecting Presence Change : END`);
    return Promise.reject();
  }

  resize() {
    const functionName = 'resize';
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `Home Componenet : START`);

      const height = document.body.scrollHeight + 1;
      if (height !== this._lastHeight) {
        //log
        this._lastHeight = height;
        amc.setAppHeight(height);
      }

      this.logger.log(LOG_LEVEL.Trace, functionName, `Home Componenet : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `Home Componenet : ERROR`, error);
    }
  }

  /**
   * Change teams presence
   *
   * @param {string} presence - Teams presence to be set
   * @param {string} userId - User id for user
   * @param {string} initiatingApp - App that initiated the change in presence
   * @memberof CallComponents
   */
  async setTeamsPresence(presence: string, userId: string, clientId: string) {
    const functionName = 'setTeamsPresence';
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `Home Componenet : START`);

      const presenceArray = presence.split('|');
      setPresence(this.authProvider, presenceArray, userId, clientId);

      this.logger.log(LOG_LEVEL.Trace, functionName, `Home Componenet : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `Home Componenet : ERROR`, error);
    }
  }

  async pollForTeamsPresence() {
    const functionName = 'pollForTeamsPresence';
    try {
      const teamsPresence = await getPresence(this.authProvider);
      const presence = teamsPresence.availability + '|' + teamsPresence.activity;
      if (teamsPresence.availability && teamsPresence.activity && presence !== this.lastTeamsPresence) {
        let davinciPresence: string;
        let davinciReason = '';

        let newChannelPresence: string;

        newChannelPresence = presence;
        this.logger.log(LOG_LEVEL.Information, functionName, `Home Component : Changing Presence to `, newChannelPresence);

        if (newChannelPresence in this.channelToDaVinci) {
          davinciPresence = this.channelToDaVinci[newChannelPresence].split('|');
          davinciReason = '';

          if (davinciPresence.length > 1) {
            davinciReason = davinciPresence[1];
          }

          davinciPresence = davinciPresence[0];
          this.logger.log(LOG_LEVEL.Information, functionName, `Home Component : DaVinci Presence`, davinciPresence);
          this.lastTeamsPresence = presence;
          amc.setPresence(davinciPresence, davinciReason);
        }
      }
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `Home Componenet : ERROR`, error);
    }
  }

  render() {
    return (
      <>
        <AuthenticatedTemplate>
          {this.callAgent && (
            <CallComponents
              callAgent={this.callAgent}
              resize={this.resize}
              setTeamsPresence={this.setPresenceToTeamsCTA}
              logger={this.logger}
              transcriptionConfig={this.props.csConfig.transcription}
              cognitiveServicesConfig={this.props.csConfig.cognitiveServices}
            />
          )}
        </AuthenticatedTemplate>
      </>
    );
  }
}

export default Home;
