import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import * as amc from "@amc-technology/davinci-api";
import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import fetch from 'node-fetch';



const initializeApp = async () => {
  try {
    const configAMC: any = await amc.getConfig();
    const tenant: any = configAMC['AzureCSConfig']['variables']['TenantID'] ? configAMC['AzureCSConfig']['variables']['TenantID'] : "";
    const clientId: any = configAMC['AzureCSConfig']['variables']['ClientID'];
    const url: any = configAMC['variables']['URL'];
    const connectionString = configAMC['AzureCSConfig']['variables']['ConnectionString'];
    amc.enableClickToDial(true);
    // Comment out below code when running locally
    // const config = await fetch(`${window.location.origin.toString()}/ClientConfiguration`).then(response => response.json());
    // const isDev =
    //   config.useDevLogger &&
    //   config.useDevLogger.toString().toLowerCase() === "true";
    // let logger = new amc.Logger(
    //   'MicrosoftTeams',
    //   isDev,
    //   config.loggerApiUrl
    // );
    // When running locally, if you want logs uncomment below
    let logger = new amc.Logger(
      'MicrosoftTeams',
      true,
      'https://loggerapi-dev.contactcanvas.com'
      // config.loggerApiUrl
    );
    await amc.initializeComplete(logger);

    const csConfig = {
      channelToDaVinci: configAMC.ChannelToDaVinci.variables,
      DaVinciToChannel:configAMC.DaVinciToChannel.variables,
      appName: configAMC.name,
      clientID: clientId,
      connectionString: connectionString,
      transcription: configAMC.Transcription.variables,
      cognitiveServices: configAMC.AzureCSConfig.variables
    }

    const pca = new PublicClientApplication({
      auth: {
        clientId: clientId,
        authority: `https://login.microsoftonline.com/${tenant}`,
        redirectUri: url,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
      }
    });

    ReactDOM.render(
      <MsalProvider instance={pca}>
      <React.StrictMode>
        <App csConfig={csConfig} logger={logger}/>
      </React.StrictMode>
      </MsalProvider>,
      document.getElementById('root')
    );


  } catch (error) {
    throw(error);
  }
};

initializeApp();
reportWebVitals();

