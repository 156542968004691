import { TeamsCallAgent, IncomingCall, Call } from '@azure/communication-calling';
import { ControlBar, EndCallButton, MicrophoneButton, VideoTile, FluentThemeProvider, Dialpad } from '@azure/communication-react';
import { PrimaryButton, Stack, registerIcons, initializeIcons } from '@fluentui/react';
import { DEFAULT_COMPONENT_ICONS } from '@azure/communication-react';
import { LOG_LEVEL, setInteraction, RecordItem, IInteraction, INTERACTION_DIRECTION_TYPES, INTERACTION_STATES, CHANNEL_TYPES, registerClickToDial } from '@amc-technology/davinci-api';
import { IncomingCallToast } from './IncomingCallComponent';
import React from 'react';
import { SpeechConfig, AudioConfig, SpeechRecognizer, SpeechRecognitionEventArgs } from 'microsoft-cognitiveservices-speech-sdk';
import {
  TextAnalyticsClient,
  AzureKeyCredential,
  AnalyzeSentimentResultArray,
  AnalyzeSentimentSuccessResult,
  AnalyzeSentimentResult,
  AnalyzeSentimentOptions,
  RecognizeCategorizedEntitiesResultArray,
  RecognizeLinkedEntitiesResultArray,
  RecognizePiiEntitiesResultArray,
  RecognizePiiEntitiesSuccessResult,
  RecognizePiiEntitiesResult,
  RecognizeCategorizedEntitiesResult,
  RecognizeCategorizedEntitiesSuccessResult,
  RecognizeLinkedEntitiesResult,
  RecognizeLinkedEntitiesSuccessResult,
} from '@azure/ai-text-analytics';
import { v4 as uuidv4 } from 'uuid';


initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

const TEAMS_DIRECTION_MAP = {
  Incoming: INTERACTION_DIRECTION_TYPES.Inbound,
  Outgoing: INTERACTION_DIRECTION_TYPES.Outbound,
};

interface props {
  callAgent: any;
  resize: any;
  setTeamsPresence: any;
  logger: any;
  transcriptionConfig: any;
  cognitiveServicesConfig: any;
}

interface state {
  isOnCall: boolean;
  incomingCallAlert: boolean;
  audioButtonChecked: boolean;
  dialpadInoutValue: string;
}
let globalIncomingCallHandler: any;
export class CallComponents extends React.Component<props, state> {
  stackTokens: any;
  dialStyle: any;
  styles: any;
  customStyles: any;
  displayName: string;
  callAgent: TeamsCallAgent;
  currentCallId: string;
  resize: any;
  incomingCallList: {
    [key: string]: any;
  };
  callList: {
    [key: string]: any;
  };
  logger: any;
  transcriptionConfig: any;
  cognitiveServicesConfig: any;
  remoteAudioStarted: boolean;
  constructor(props: any) {
    super(props);
    this.logger = props.logger;
    this.logger.log(LOG_LEVEL.Critical, 'Constructor', 'CallComponent : START');
    this.transcriptionConfig = props.transcriptionConfig;
    this.cognitiveServicesConfig = props.cognitiveServicesConfig;
    globalIncomingCallHandler = this.incomingCallHandler.bind(this);
    this.clickToDial = this.clickToDial.bind(this);
    this.startCall = this.startCall.bind(this);
    this.acceptCall = this.acceptCall.bind(this);
    this.rejectCall = this.rejectCall.bind(this);
    this.onChange = this.onChange.bind(this);
    this.endCall = this.endCall.bind(this);
    this.callAgent = props.callAgent;
    this.resize = props.resize;
    this.displayName = '';
    this.currentCallId = '';
    this.incomingCallList = {};
    this.callList = {};
    this.remoteAudioStarted = false;
    this.state = { isOnCall: false, incomingCallAlert: false, audioButtonChecked: true, dialpadInoutValue: '' };
    this.stackTokens = { childrenGap: 40 };
    this.dialStyle = { root: { margin: 0, border: 'solid .5px', paddingBottom: '36px', paddingTop: '1.1rem', paddingRight: '1.1rem', paddingLeft: '1.1rem', maxWidth: 'inherit', minWidth: '121%' } };
    this.styles = { root: { border: '0.5px solid', borderRadius: '3px', width: 'fit-content', marginLeft: '14px' } };
    this.customStyles = {
      root: { height: '230px', width: '286x', border: '1px solid #999', minWidth: '200px', marginLeft: '14px', marginBottom: '10px' },
      displayNameContainer: { marginLeft: '50%', marginBottom: '10%', transform: 'translate(-50%, -50%)' },
    };
    if (props.callAgent) {
      props.callAgent?.on('incomingCall', (args: any) => {
        globalIncomingCallHandler(args);
      });
    }
    registerClickToDial((phoneNumber) => {
      const functionName = 'registerClickToDialCallback';
      try {
        this.clickToDial(phoneNumber);
      } catch (error) {
        this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenet : ERROR`, error);
      }
      return Promise.resolve();
    });
    this.beginTranscription = this.beginTranscription.bind(this);
  }

  async clickToDial(phoneNumber: string) {
    const functionName = 'clickToDial';
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      this.startCall(phoneNumber);

      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenet : ERROR`, error);
    }
  }

  incomingCallHandler(args: { incomingCall: IncomingCall }) {
    const functionName = 'incomingCallHandler';
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`, args.incomingCall);

      this.currentCallId = args.incomingCall.id;
      this.setState({ incomingCallAlert: true });
      if (!this.incomingCallList.hasOwnProperty(args.incomingCall.id)) {
        this.incomingCallList[args.incomingCall.id] = args.incomingCall;
        this.displayName = this.incomingCallList[args.incomingCall.id].callerInfo.displayName ? this.incomingCallList[args.incomingCall.id].callerInfo.displayName : '';
        this.prepareAndSetInteraction(this.incomingCallList[this.currentCallId], INTERACTION_STATES.Alerting);
        // Subscribe to callEnded event and get the call end reason
        this.incomingCallList[args.incomingCall.id].on('callEnded', (args: any) => {
          this.setState({ isOnCall: false });
          this.setState({ incomingCallAlert: false });
          this.prepareAndSetInteraction(this.incomingCallList[this.currentCallId], INTERACTION_STATES.Disconnected);
          // callEndReason is also a property of IncomingCall
          var callEndReason = this.incomingCallList[args.incomingCall.id].callEndReason;
        });
      }

      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenet : ERROR`, error);
    }
  }

  async startCall(number?: string) {
    const functionName = 'startCall';
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
      this.displayName = '';
      const pNumber = number ? number : this.state.dialpadInoutValue;
      this.setState({ dialpadInoutValue: pNumber });
      const phoneCallee = { phoneNumber: this.state.dialpadInoutValue };
      if (this.callAgent) {
        const outboundCall = await this.callAgent.startCall(phoneCallee);
        this.currentCallId = outboundCall.id;
        this.callList[this.currentCallId] = outboundCall;
        this.setState({ isOnCall: true });
        const callObject = {
          phoneNumber: this.state.dialpadInoutValue,
          kind: 'phoneNumber',
          id: outboundCall.id,
        };
        this.callList[this.currentCallId].callObject = callObject;
        this.prepareAndSetInteraction(this.callList[this.currentCallId], INTERACTION_STATES.Initiated);
        outboundCall.on('stateChanged', async () => {
          if (outboundCall.state === 'Connected') {
            this.prepareAndSetInteraction(this.callList[this.currentCallId], INTERACTION_STATES.Connected);
            this.setState({ dialpadInoutValue: '' });
          } else if (outboundCall.state === 'Disconnected') {
            this.setState({ isOnCall: false });
            this.prepareAndSetInteraction(this.callList[this.currentCallId], INTERACTION_STATES.Disconnected);
          }
        });
      }
      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenet : ERROR`, error);
    }
  }

  constructCADObject(rawCADData: any) {
    const functionName = 'constructCADObject';
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
      if (rawCADData) {
        const cadObject: any = {};
        for (const cad in rawCADData) {
          if (rawCADData.hasOwnProperty(cad)) {
            cadObject[cad] = {
              DevName: '',
              DisplayName: '',
              Value: rawCADData[cad],
            };
          }
        }
        this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
        return cadObject;
      } else {
        this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
        return undefined;
      }
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenet : ERROR`, error);
    }
  }

  async acceptCall() {
    const functionName = 'acceptCall';
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      this.callList[this.currentCallId] = await this.incomingCallList[this.currentCallId].accept();
      this.setState({ isOnCall: true });
      this.setState({ incomingCallAlert: false });
      this.prepareAndSetInteraction(this.callList[this.currentCallId], INTERACTION_STATES.Connected);
      this.resize();

      this.transcriptionHandler(this.callList[this.currentCallId]);

      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenet : ERROR`, error);
    }
  }

  async rejectCall() {
    const functionName = 'rejectCall';
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      const call = await this.incomingCallList[this.currentCallId];
      call.reject();
      this.setState({ isOnCall: false });
      this.setState({ incomingCallAlert: false });
      this.prepareAndSetInteraction(this.callList[this.currentCallId], INTERACTION_STATES.Disconnected);
      this.resize();

      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenet : ERROR`, error);
    }
  }

  async endCall() {
    const functionName = 'endCall';
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      const call = await this.callList[this.currentCallId];
      call.hangUp({
        forEveryone: true,
      });
      this.setState({ isOnCall: false });
      if (call.callObject) {
        this.prepareAndSetInteraction(call, INTERACTION_STATES.Disconnected);
      } else {
        this.prepareAndSetInteraction(this.callList[this.currentCallId], INTERACTION_STATES.Disconnected);
      }

      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenet : ERROR`, error);
    }
  }

  /**
   * Place the current call on hold
   *
   * @memberof CallComponents
   */
  async holdCall() {
    const functionName = `holdCall`;
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponents : START`);

      const call = await this.incomingCallList[this.currentCallId];
      await call?.hold();
      this.prepareAndSetInteraction(call, INTERACTION_STATES.OnHold);

      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponents : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenets : ERROR`, error);
    }
  }

  /**
   * Unhold the current call
   *
   * @memberof CallComponents
   */
  async resumeCall() {
    const functionName = `resumeCall`;
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponents : START`);

      const call = await this.incomingCallList[this.currentCallId];
      await call?.resume();
      this.prepareAndSetInteraction(call, INTERACTION_STATES.Connected);

      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponents : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenets : ERROR`, error);
    }
  }

  // TODO: Create a teams interaction object instead of using any for params
  /**
   * Before an interaction is send to the DaVinci Framework, the interaction needs to be formulated with
   * the proper call data. If there is any CAD, the CAD will be added to the details of the interaction.
   *
   * @memberof CallComponents
   */
  prepareAndSetInteraction(params: any, state: INTERACTION_STATES, CAD?: any, channel = CHANNEL_TYPES.Telephony): void {
    const functionName = 'prepareAndSetInteraction';
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponents : START`);

      const details = new RecordItem('', '', '');
      if (CAD !== undefined) {
        const cadKeys = Object.keys(CAD);
        for (const key of cadKeys) {
          details.setField(key, '', '', CAD[key]);
        }
      }

      let direction: INTERACTION_DIRECTION_TYPES = params.direction ? TEAMS_DIRECTION_MAP[params.direction as keyof typeof TEAMS_DIRECTION_MAP] : INTERACTION_DIRECTION_TYPES.Inbound;

      let callInfo;
      if (direction === INTERACTION_DIRECTION_TYPES.Inbound) {
        callInfo = {
          interaction_id: params.id,
          scenario_id: params.id,
          callType: params.callerInfo.identifier?.kind,
          phoneNumber: params.callerInfo.identifier.phoneNumber,
          CAD: params.callerInfo,
        };
      } else if (direction === INTERACTION_DIRECTION_TYPES.Outbound) {
        callInfo = {
          interaction_id: params.callObject.id,
          scenario_id: params.callObject.id,
          callType: params.callObject.kind,
          phoneNumber: params.callObject.phoneNumber,
          CAD: params.callObject,
        };
      }

      const cad = Object.assign({}, callInfo?.CAD);
      const cadObject = this.constructCADObject(cad);
      for (const callData in cadObject) {
        details.setField(callData, '', '', cadObject[callData]);
      }

      if (channel === CHANNEL_TYPES.Telephony && callInfo?.callType === 'phoneNumber') {
        details.setPhone('', '', callInfo?.phoneNumber);
      }

      const newInteraction: IInteraction = {
        interactionId: callInfo?.interaction_id,
        scenarioId: callInfo?.scenario_id,
        state: state,
        channelType: channel,
        direction: direction,
        details: details,
      };

      this.logger.log(LOG_LEVEL.Debug, functionName, `CallComponents : Interaction Created : ${newInteraction}`);

      setInteraction(newInteraction);

      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponents : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenets : ERROR`, error);
    }
  }

  onChange(input: string) {
    const functionName = 'onChange';
    try {
      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponents : START`);

      this.setState({ dialpadInoutValue: input });

      this.logger.log(LOG_LEVEL.Trace, functionName, `CallComponents : END`);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenets : ERROR`, error);
    }
  }

  async transcriptionHandler(call: Call) {
    const functionName = 'transcriptionHandler';
    try {
      this.logger.log(LOG_LEVEL.Critical, functionName, `CallComponents : START`, [call, this.remoteAudioStarted, call.direction]);
      // Make sure call is defined before creating event listener; should only happen once; only supports inbound calls
      if (call && call.direction === 'Incoming') {
        this.remoteAudioStarted = true;
        // Only Transcribe if configured; configured in Creators Studio; retrieved from an object in index.tsx
        if (this.transcriptionConfig.Enabled === true) {
          // The remoteAudioStreamArray shows what was added/removed to remoteAudioStreams
          // Begin listening to the remoteAudioStreamsUpdated event
          call.on('remoteAudioStreamsUpdated', this.beginTranscription);
        }
      }
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenets : ERROR`, error);
    }
  }

  async beginTranscription() {
    const functionName = 'beginTranscription';
    try {
      let call: Call = await this.callList[this.currentCallId];
      const textAnalysisClient = new TextAnalyticsClient('https://textanalyticsinternal.cognitiveservices.azure.com/', new AzureKeyCredential('6fcc68aaeaf34a46b5ba4813a20bdfb2'));

      // Configurations needed to utilize Azure Cognitive Services
      const speechConfig: SpeechConfig = SpeechConfig.fromSubscription(this.transcriptionConfig.SpeechKey, this.transcriptionConfig.SpeechRegion);

      // Grab the MediaStream object from the remoteAudioStreams array
      // remoteAudioStreams is part of the call object
      // It contains an array of remoteAudioStream objects
      // getMediaStream() gets the MediaStream associated with the RemoteAudioStream
      const mediaStream: MediaStream | undefined = await call?.remoteAudioStreams[0].getMediaStream(); // This only grabs one audio stream

      // Create AudioConfig based off MediaStream - clientAudio
      const clientAudioConfig: AudioConfig = AudioConfig.fromStreamInput(mediaStream as MediaStream);

      // Create AudioConfig based off Agent's microphone
      const agentAudioConfig: AudioConfig = AudioConfig.fromDefaultMicrophoneInput();

      // Create SpeechRecognizer based on the SpeechConfig and AudioConfig for the client
      const clientSpeechRecognizer: SpeechRecognizer = new SpeechRecognizer(speechConfig, clientAudioConfig);

      // Create SpeechRecognizer based on the SpeechConfig and AudioConfig for the agent
      const agentSpeechRecognizer: SpeechRecognizer = new SpeechRecognizer(speechConfig, agentAudioConfig);

      // Begin listening to transcriptions from the client
      clientSpeechRecognizer.startContinuousRecognitionAsync(
        () => {
          console.log('Starting Client Transcription');
          call?.on('remoteAudioStreamsUpdated', () => {
            if (call?.remoteAudioStreams.length === 0) {
              console.log('Ending Transcription');
              clientSpeechRecognizer.stopContinuousRecognitionAsync();
              this.endCall();
            }
          });
        },
        (e: string) => {
          console.log(e);
        }
      );

      // Begin listening to transcriptions from the agent
      agentSpeechRecognizer.startContinuousRecognitionAsync(
        () => {
          console.log('Starting Agent Transcription');
          call?.on('remoteAudioStreamsUpdated', () => {
            if (call?.remoteAudioStreams.length === 0) {
              console.log('Ending Transcription');
              agentSpeechRecognizer.stopContinuousRecognitionAsync();
            }
          });
        },
        (e: string) => {
          console.log(e);
        }
      );

      // Constantly firing as words are detected
      clientSpeechRecognizer.recognizing = (s, e) => {
        console.log('Client: ' + e.result.text);
        console.log('Client Offset in Ticks: ' + e.result.offset);
        console.log('Client Duration in Ticks: ' + e.result.duration);
      };

      // Only triggered when sentences are completed (pause in audio) or based on a configured time.
      // Default configured time is 15 seconds.
      clientSpeechRecognizer.recognized = async (s, e) => {
        if (e.result.text !== undefined && e.result.text !== '') {
          let text: string = e.result.text;
          // Below, Azure Language resource has textAnalysisClient. This allows us to use the various methods below.
          // These allow us to determine attributes of the clients dialogue such as, sentiment, personally identifiable information, items spoken of and their categories
          // as well as wikipedia links to items the client references.
          const clientText: string[] = [text];
          const sentimentAbility: AnalyzeSentimentResultArray = await textAnalysisClient.analyzeSentiment(clientText); // Creating the sentiment array. Contains the sentiment
          const piiAbility: RecognizePiiEntitiesResultArray = await textAnalysisClient.recognizePiiEntities(clientText); // Creating the Private identifiable information Array. Contains any PII
          // const categorizationAbility: RecognizeCategorizedEntitiesResultArray =  await textAnalysisClient.recognizeEntities(clientText); //Creating categories for the text. If there are any categories.
          // const linkAbility: RecognizeLinkedEntitiesResultArray = await textAnalysisClient.recognizeLinkedEntities(clientText); // creating Links for the text if there are valid Wikipedia links for the text.

          // Below are the actual results of their corresponding attribute.
          const sentimentResult: AnalyzeSentimentResult = sentimentAbility[0] as AnalyzeSentimentSuccessResult;
          const piiResult: RecognizePiiEntitiesResult = piiAbility[0] as RecognizePiiEntitiesSuccessResult;
          // const categorizationResult: RecognizeCategorizedEntitiesResult = categorizationAbility[0] as RecognizeCategorizedEntitiesSuccessResult;
          // const linkResult: RecognizeLinkedEntitiesResult = linkAbility[0] as RecognizeLinkedEntitiesSuccessResult;

          let analysis: string = text + '\n\nSENTIMENT: ' + sentimentResult.sentiment;

          // // Only prints if it exisits; not gauranteed to be defined.
          // if (piiResult.entities[0]) {
          //     analysis += "\n\nPII: " + piiResult.redactedText;
          // }

          // if (categorizationResult.entities[0]) {
          //     analysis += "\n\n";
          //     for(let i = 0; i < categorizationResult.entities.length; i++) {
          //         analysis += "Type: " + categorizationResult.entities[i]?.category + "\n";
          //     }

          // }

          // if (linkResult.entities[0]) {
          //     analysis += "\n";
          //     for(let i = 0; i < linkResult.entities.length; i++)
          //     {
          //         analysis += "Link: " + linkResult.entities[i]?.url + "\n";
          //     }
          // }

          this.sendTranscription(call, 'END_USER', text, sentimentResult.sentiment);
          console.log(`%cTestSentiment: ${sentimentResult.sentiment} | ${text}`, 'background: #7CFF82; color: #00A007; padding: 2px; border-radius:2px');
        }
        console.log('Client - RECOGNIZED:  ' + e.result.text);
      };

      // Constantly firing as words are detected
      agentSpeechRecognizer.recognizing = (s, e) => {
        console.log('Agent: ' + e.result.text);
        console.log('Agent Offset in Ticks: ' + e.result.offset);
        console.log('Agent Duration in Ticks: ' + e.result.duration);
      };

      // Only triggered when sentences are completed (pause in audio) or based on a configured time.
      // Default configured time is 15 seconds.
      agentSpeechRecognizer.recognized = (s, e) => {
        if (e.result.text !== undefined && e.result.text !== '') {
          this.sendTranscription(call, 'HUMAN_AGENT', e.result.text);
        }
        console.log('Agent - RECOGNIZED:  ' + e.result.text);
      };
      // Stop listening to remoteAudioStreamsUpdated event
      call?.off('remoteAudioStreamsUpdated', this.beginTranscription);
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenets : ERROR`, error);
    }
  }

  private async sendTranscription(call: Call, relation: string, transcript: String, analysis?: string) {
    const functionName = 'sendTranscription';
    try {
      const CAD: object = {
        relation,
        transcript,
        analysis,
      };

      this.prepareAndSetInteraction(call, this.state.isOnCall ? INTERACTION_STATES.Connected : INTERACTION_STATES.Disconnected, CAD, CHANNEL_TYPES.Telephony);

      //TODO: Make sure to uncomment out the fetch function here for BYOT functionality
      // const URL: string = props.csConfig.transcriptionServiceURL;
      // const accountId: string = props.csConfig.accountId;
      // const header = {
      //     'Content-Type': 'application/json',
      //     accountid: accountId,
      //     type: 'transcriptionEvent',
      // };

      // // Info needed for sending/updating transcript data
      // const body = {
      //     call_id: callID.current,
      //     text_body: transcript,
      //     startTime: Math.ceil(new Date().getTime()),
      //     endTime: Math.ceil(new Date().getTime()) + 1000,
      //     senderType: relation,
      //     analysis: analysis
      // };

      // if (body.text_body) {
      //   // Actually sends POST to logic app
      //   fetch(URL, {
      //       method: 'POST',
      //       headers: header,
      //       body: JSON.stringify(body),
      //   });
      // }
    } catch (error) {
      this.logger.log(LOG_LEVEL.Error, functionName, `CallComponenets : ERROR`, error);
    }
  }

  render() {
    const isOnCall = this.state.isOnCall;
    let audioButtonChecked = this.state.audioButtonChecked;
    const incomingCallAlert = this.state.incomingCallAlert;
    if (incomingCallAlert) {
      this.resize();
    }

    return (
      <>
        <div>
          {!isOnCall ? (
            <FluentThemeProvider>
              {incomingCallAlert && (
                <div style={{ display: 'flex' }}>
                  <div className='incomingCallToast' style={{ flex: '0 1 50%' }}>
                    <IncomingCallToast callerName={this.displayName} alertText={'incoming Call'} onClickAccept={this.acceptCall} onClickReject={this.rejectCall} />
                  </div>
                </div>
              )}
              <div style={{ maxWidth: '16rem', marginLeft: '45%', marginBottom: '10%', transform: 'translate(-50%, 5%)' }}>
                <Stack style={{ margin: 0 }}>
                  <Dialpad styles={this.dialStyle} onChange={this.onChange} />
                </Stack>
                <Stack horizontal tokens={this.stackTokens}>
                  <PrimaryButton text='Call' onClick={() => this.startCall()} allowDisabledFocus className='callBtn' />
                </Stack>
              </div>
            </FluentThemeProvider>
          ) : (
            <div>
              <VideoTile styles={this.customStyles} displayName={this.displayName} renderElement={null} isMirrored={true} />
              <ControlBar styles={this.styles}>
                <MicrophoneButton
                  checked={audioButtonChecked}
                  onClick={() => {
                    this.setState({ audioButtonChecked: !audioButtonChecked });
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    this.callList[this.currentCallId].isMuted ? this.callList[this.currentCallId].unmute() : this.callList[this.currentCallId].mute();
                  }}
                />
                <EndCallButton onClick={this.endCall} />
              </ControlBar>
            </div>
          )}
        </div>
      </>
    );
  }
}
export default CallComponents;
