import { ERROR_CODE, Logger, getConfig, LOG_LEVEL } from '@amc-technology/davinci-api';

export default class LoggerService {
    private localLog = true;
    private logger: Logger;

    constructor(logger: any) {
        this.logger = logger;
    }

    /**
   * Log Levels
   * Loop = 0
   * Trace = 1
   * Debug = 2
   * Information = 3
   * Warning = 4
   * Error = 5
   * Critical = 6
   * None = 100
   */

  /**
   * Logs a message to the loggerApi
   *
   * @param {LOG_LEVEL} logLevel - The level of the log
   * @param {string} fName - Name of the function logging the message
   * @param {string} message - Message to log
   * @param {*} [object] - Optional object to log
   * @param {ERROR_CODE} [errorCode] - Optional error code
   * @param {Date} [localTime] - Optional local time
   * @memberof LoggerService
   */
    async log(logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) {
        const functionName = 'log';
        try {
        if (this.localLog) {
            this.localLogMessage(logLevel, fName, message, object, errorCode, localTime);
        }
        switch (logLevel) {
            case LOG_LEVEL.Loop:
            this.logger.logLoop(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
            break;
            case LOG_LEVEL.Trace:
            this.logger.logTrace(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
            break;
            case LOG_LEVEL.Information:
            this.logger.logInformation(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
            break;
            case LOG_LEVEL.Warning:
            this.logger.logWarning(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
            break;
            case LOG_LEVEL.Error:
            this.logger.logError(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
            break;
            case LOG_LEVEL.Critical:
            this.logger.logCriticial(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
            break;
            case LOG_LEVEL.Debug:
            this.logger.logDebug(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
            break;
            default:
            this.logger.logInformation(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
            break;
        }
        } catch (e) {
        this.logger.logError(`${functionName} - Failed to log ${logLevel} from ${fName}. Error: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        console.log(`${functionName} - Failed to log ${logLevel} from ${fName}. Error: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
    }

    /**
     * This function logs a message to the console that is color coded based off of its LOG_LEVEL
     *
     * @private
     * @param {LOG_LEVEL} logLevel
     * @param {string} fName
     * @param {string} message
     * @param {*} [object]
     * @param {ERROR_CODE} [errorCode]
     * @param {Date} [localTime]
     * @memberof LoggerService
     */
    private localLogMessage(logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) {
        const functionName = 'localLogMessage';
        try {
        let backgroundColor = '#003300';
        let textColor = '#00ff00';

        switch (logLevel) {
            case LOG_LEVEL.Loop:
            case LOG_LEVEL.Trace:
            backgroundColor = '#000033';
            textColor = '#0077ff';
            break;
            case LOG_LEVEL.Information:
            case LOG_LEVEL.Debug:
            backgroundColor = '#003300';
            textColor = '#00ff00';
            break;
            case LOG_LEVEL.Warning:
            case LOG_LEVEL.Error:
            case LOG_LEVEL.Critical:
            backgroundColor = '#330000';
            textColor = '#ff0000';
            break;
            default:
            backgroundColor = '#003300';
            textColor = '#00ff00';
            break;
        }
        const fontWeight = 'bold';
        console.log(`%c${fName} | ${LOG_LEVEL[logLevel]} | ${message}${object ? `\n\n${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ''}`, `background: ${backgroundColor}; color: ${textColor}; font-weight: ${fontWeight};`);
        } catch (e) {
        console.log(`${functionName} - Failed to log ${logLevel} from ${fName}. Error: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
        }
    }

    public async pushLogsAsync() {
        this.logger.pushLogsAsync();
    }
}