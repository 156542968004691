import { MsalProvider, useMsal } from '@azure/msal-react';
import Home from "./Home";
import { useEffect, useState } from 'react';
import LoggerService from './logger';
import UserComponent from './UserComponent';
import { TeamsCallAgent } from '@azure/communication-calling';
import { v4 as uuidv4 } from 'uuid';
import { LOG_LEVEL } from "@amc-technology/davinci-api";

interface props {
  csConfig: any; 
  logger: any;
}

const App: React.FC<props> = ({csConfig, logger}) => {
  const { instance, accounts } = useMsal();
  const [callAgent, setCallAgent] = useState<TeamsCallAgent>();
  const [loggerServiceTeams, setLoggerService] = useState<LoggerService>();
  const [error, setError] = useState('');
  
  useEffect(() => {
    const fetchDataAsync = async () => {
      const ddata = await signin(accounts, instance,csConfig.connectionString, csConfig.clientID); 
      const loggerServiceInitial = await createLoggerService(logger);
      setLoggerService(loggerServiceInitial);
      setCallAgent(ddata);
    };
    fetchDataAsync();
  }, [error]);

  const createLoggerService = async (logger: any) => {
    const functionName = 'createLoggerService';
    try {
      let loggerService = new LoggerService(logger);
      return loggerService;
    } catch (error) {
      throw(error);
    }
  }

  const signin = async (accounts: any, instance: any,  connectionString: string, clientID: string) => {
    const functionName = 'signin';
    try{
      if(accounts.length === 0) {
        await instance.loginPopup({
            scopes: ['https://auth.msft.communication.azure.com/Teams.ManageCalls', 'https://auth.msft.communication.azure.com/Teams.ManageChats']
          })        
        }
        if(accounts.length > 0) {
          const userObject = new UserComponent(instance, connectionString, clientID);
          const callAgent =  await  userObject.createCallAgent();
          return callAgent;
        } else {
          loggerServiceTeams?.log(LOG_LEVEL.Error, functionName, `CallComponenet : ERROR`, error);
          let errorId = uuidv4();
          setError('Error occur'+ errorId);
        }

    } catch (error) {
      let errorId = uuidv4();
      setError('Error occur' + errorId);
      loggerServiceTeams?.log(LOG_LEVEL.Error, functionName, `CallComponenet : ERROR`, error);
    }
  };    
  
  return (
      <div>
         {callAgent &&
          <Home instance={instance} csConfig={csConfig} callAgent={callAgent} logger={loggerServiceTeams}/>
         }
      </div>
  );   
};

export default App;


