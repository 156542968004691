import { CallClient, TeamsCallAgent } from "@azure/communication-calling";
import { getConfig } from "@amc-technology/davinci-api";
import {getUser} from './GraphService';
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { InteractionStatus, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { CommunicationIdentityClient } from "@azure/communication-identity";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";


class UserComponent {
    instance: any;
    authProvider: AuthCodeMSALBrowserAuthenticationProvider;
    callAgent: any;
    clientId: string;
    connectionString: string;
    tokenCredential: any;
    userId: string;
    accounts: any;
    constructor(instance:any, connectionString: string, clientId: string) {
        this.instance = instance;
        this.clientId =clientId;
        
        this.connectionString = connectionString;
        this.callAgent = {};
        this.accounts = this.instance.getAllAccounts();
        this.userId = this.accounts[0].localAccountId;
        
        this.authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
            this.instance as PublicClientApplication,
            {
              account: this.accounts[0],
              scopes: ["https://graph.microsoft.com/.default"] ,
              interactionType: InteractionType.Popup
            }
        );  
    }

    async createCallAgent() {
        try {
            const request = {
                scopes: ['https://auth.msft.communication.azure.com/Teams.ManageCalls', 'https://auth.msft.communication.azure.com/Teams.ManageChats'], 
                account: this.accounts[0],
            };
            try{
                const response = await this.instance.acquireTokenSilent(request);
                const accessToken = response.accessToken;
                const connectionString = this.connectionString;
                const client = new CommunicationIdentityClient(connectionString);
                    
                let Atoken = await client.getTokenForTeamsUser({
                    teamsUserAadToken: accessToken,
                    clientId: this.clientId,
                    userObjectId: this.accounts[0].localAccountId,
                });
                let tokenCredential = new AzureCommunicationTokenCredential(Atoken.token);
                const callClient = new CallClient();
                this.callAgent = await callClient.createTeamsCallAgent(tokenCredential);
                return this.callAgent;
                } catch (error) {
                    throw error;
                }
        } catch (error) {
            throw error;
        }
    }

    getUserId() {
        return this.userId;
    }

    getAuthProvider() {
        return this.authProvider;
    }

    


}
export default UserComponent;
